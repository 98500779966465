import {
  E164_PHONE_NUMBER_REGEX,
  ERROR_FAILED_RECAPTCHA
} from '@hypercharge/bbs-website-commons/lib/constants';
import { ContactForm } from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Button, notification } from 'antd';
import { Field, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { parseUrl } from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Address } from '../components/Address';
import { FormInput } from '../components/FormInput';
import { LayoutFixedWidthWrapper } from '../components/LayoutFixedWidthWrapper';
import { Loading } from '../components/Loading';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { useConfiguration } from '../context/ConfigurationProvider';
import { sendContactForm } from '../context/ContactForm';
import {
  BBS_PRIMARY_COLOR,
  BBS_TEXT_COLOR,
  BREAKPOINTS,
  MEDIA_QUERIES_MAX,
  QUERY_OPTIONS
} from '../utils/constants';
import { RequestState } from '../utils/types';
import { MetaTags } from '../components/MetaTags';
import CountrySelect from '../components/CountrySelect';
import FormikCheckbox from '../components/FormikCheckbox';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const ScWidthLimiter = styled(LayoutFixedWidthWrapper)`
    margin: 0 auto 20px auto;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 0 10px 0 10px;
    }
`;

const ScContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 2px 2px 4px 2px #e8e8e8;
    padding: 1rem;
`;

const ScFormWrapper = styled.div``;

const ScContactContainer = styled.div`
    margin-right: 2rem;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        margin: 15px 0 15px 0;
    }
    @media screen and (min-width: ${BREAKPOINTS.sm}px) {
        min-width: 300px;
    }
`;

const ScContentInRow = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        flex-wrap: wrap;
    }
`;

const ScTitle = styled.h1`
    font-size: 30px;
    margin-bottom: 1rem;
    color: ${BBS_TEXT_COLOR};
`;

const ScSubtitle = styled.h2`
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 1rem;
    font-weight: 400;

    color: ${BBS_TEXT_COLOR};
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: auto;
    }
`;

const ScFormContainer = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .ant-row {
        @media screen and (max-width: ${BREAKPOINTS.sm}px) {
            input {
                width: 90%;
            }
        }
    }
`;

const ScLabel = styled.label<{ fullWidth?: boolean }>`
    padding: 5px;
    width: ${({ fullWidth }) => !fullWidth ? 50 : 100}%;

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: 100%;
    }
`;

const ScMessageLabel = styled.label`
    padding: 5px 5px 0;
    width: 100%;

    div {
        margin-top: 4px;
    }

    .ant-row {
        textarea {
            width: 100%;
        }
    }

    .ant-form-item {
        margin-bottom: 8px;
    }

    .ant-form-explain {
        display: none;
    }
`;

const ScField = styled(Field)`
    width: 100% !important;
    resize: none;
`;

const ScActions = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const ScGoogleReCaptcha = styled(GoogleReCaptcha)`
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        width: 100%;
    }
`;

const ScSubmitBtn = styled(Button)`
    background-color: ${BBS_PRIMARY_COLOR};
    color: #ffffff;
    justify-self: right;
    align-self: flex-end;
    flex-grow: 1;
    max-width: 250px;
    @media screen and (max-width: ${BREAKPOINTS.md}px) {
        margin-top: 30px;
    }
`;

const ScFullSizedContainer = styled.div`
    position: relative;
`;

const ScPhoneNumberInput = styled(PhoneNumberInput)`
    padding-top: 4px;
`;

const MapsIframe = styled.iframe`
    width: 100%;
    height: 300px;
    margin-top: 1rem;
    border: 0;
    margin-bottom: -6px; /* rendering bug due to iframe */

    ${MEDIA_QUERIES_MAX.md} {
        margin-top: 0;
    }
`;

const Contact = ({ breadcrumbs }: { breadcrumbs: React.ReactNode }) => {
  const location = useLocation();
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const { companyInformation, setAppStyle, appStyle } = useConfiguration();

  const [initialMessage, setInitialMessage] = useState<string>('');
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);
  const recaptchaRef = useRef<any | null>(null);

  const searchParams = useMemo(() => ({ ...parseUrl(location.search, QUERY_OPTIONS).query }), [
    location.search
  ]);
  const vehicleId = (searchParams.id as string) || undefined;

  const initialValues: ContactForm = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    message: initialMessage,
    language,
    vehicleId,
    privacyPolicy: false
  };

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(' '),
        lastName: Yup.string().required(' '),
        phoneNumber: Yup.string()
          .required(' ')
          .matches(E164_PHONE_NUMBER_REGEX, t('CONTACT__ERROR_PHONE_NUMBER')),
        email: Yup.string()
          .required(' ')
          .email(t('NEWSLETTER__INVALID_EMAIL'))
          .required(' '),
        message: Yup.string().required(' '),
        countryCode: Yup.string().required(' '),
        privacyPolicy: Yup.boolean().oneOf([true], t('CONTACT__ERROR_PRIVACY_POLICY'))
      }),
    [t]
  );

  useEffect(() => {
    const vehicleName = searchParams.vehicleName as string;
    const vehicleReference = searchParams.vehicleReference as string;
    if (vehicleName && vehicleReference) {
      setInitialMessage(
        t('CONTACT__FORM_VEHICLE_MESSAGE', {
          vehicleName,
          vehicleReference
        })
      );
    } else if (searchParams.missingVehicle) {
      setInitialMessage(t('CONTACT__FORM_MISSING_VEHICLE_MESSAGE'));
    }
  }, [searchParams, t]);

  useEffect(() => {
    if (appStyle.showMarginBeforeFooter) {
      setAppStyle({ ...appStyle, showMarginBeforeFooter: false });
    }
    return () => {
      if (!appStyle.showMarginBeforeFooter) {
        setAppStyle({ ...appStyle, showMarginBeforeFooter: true });
      }
    };
  }, [appStyle, setAppStyle]);

  if (!companyInformation) {
    return <Loading />;
  }

  const onSubmit = async (formValues: ContactForm, actions) => {
    const { requestState, error } = await sendContactForm(formValues, recaptchaResponse || '');

    if (requestState === RequestState.ERROR) {
      const message = {
        message: t('ERROR_BOUNDARY__TITLE'),
        description: ''
      };

      if (error === ERROR_FAILED_RECAPTCHA) {
        message.description = t('CONTACT__ERROR_CAPTCHA');
      }

      notification.error({ ...message });
    }
    if (requestState === RequestState.SUCCESS) {
      actions.resetForm();
      actions.setSubmitting(false);
      notification.success({ message: t('CONTACT__SUCCESS') });
      setInitialMessage('');
    }
    if (recaptchaRef && recaptchaRef.current && recaptchaRef.current.reset) {
      recaptchaRef.current.reset();
    }
  };

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
      language={language}
      container={{
        element: 'google-captcha-element',
        parameters: {}
      }}
    >
      <MetaTags title={t('NAVIGATION__CONTACT')} description={t('CONTACT__TEXT')} />
      <ScFullSizedContainer>
        <ScWidthLimiter>
          {breadcrumbs}
          <ScContent>
            <ScTitle>{t('CONTACT__HEADER')}</ScTitle>
            <ScContentInRow>
              <ScContactContainer>
                <ScSubtitle>{t('CONTACT__TEXT')}</ScSubtitle>
                <Address {...companyInformation} showCompanyName={false} />
              </ScContactContainer>
              <ScFormWrapper>
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={formSchema}
                  enableReinitialize={true}
                >
                  {(formProps: FormikProps<ContactForm>) => {
                    return (
                      <ScFormContainer noValidate
                                       onSubmit={formProps.handleSubmit}>
                        <ScLabel htmlFor="firstName">
                          {t('CONTACT__FORM_FIRSTNAME')}
                          <ScField component={FormInput}
                                   disabled={formProps.isSubmitting}
                                   name="firstName"
                                   onChange={formProps.handleChange}
                                   size="default"
                                   type="text"
                                   value={formProps.values.firstName}
                                   placeholder="Olivia" />
                        </ScLabel>

                        <ScLabel htmlFor="lastName">
                          {t('CONTACT__FORM_LASTNAME')}
                          <ScField component={FormInput}
                                   disabled={formProps.isSubmitting}
                                   name="lastName"
                                   onChange={formProps.handleChange}
                                   size="default"
                                   type="text"
                                   value={formProps.values.lastName}
                                   placeholder="Peeters" />
                        </ScLabel>

                        <ScLabel htmlFor="email">
                          {t('CONTACT__FORM_EMAIL')}
                          <ScField component={FormInput}
                                   disabled={formProps.isSubmitting}
                                   name="email"
                                   onChange={formProps.handleChange}
                                   size="default"
                                   type="text"
                                   value={formProps.values.email}
                                   placeholder="emma.peeters@example.com" />
                        </ScLabel>

                        <ScLabel htmlFor="phoneNumber">
                          {t('CONTACT__FORM_PHONE')}
                          <ScPhoneNumberInput valid={!formProps.errors.phoneNumber}
                                              value={formProps.values.phoneNumber}
                                              disabled={formProps.isSubmitting}
                                              onChange={value => {
                                                formProps.setFieldValue(
                                                  'phoneNumber',
                                                  value.replace(/[^0-9+]+/g, '')
                                                );
                                              }} />
                        </ScLabel>

                        <ScLabel htmlFor="countryCode" fullWidth={true}>
                          <label>{t('CONTACT__FORM_COUNTRY')}</label>

                          <CountrySelect value={formProps.values.countryCode}
                                         onChange={value => {
                                           formProps.setFieldValue('countryCode', value);
                                         }}
                                         onBlur={() => {
                                           formProps.setFieldTouched('countryCode');
                                         }}
                                         disabled={formProps.isSubmitting}
                                         valid={!(formProps.errors.countryCode)}
                                         placeholder={t('CONTACT__FORM_COUNTRY_PLACEHOLDER')} />
                        </ScLabel>

                        <ScMessageLabel htmlFor="message">
                          {t('CONTACT__FORM_MESSAGE')}
                          <ScField component={FormInput}
                                   disabled={formProps.isSubmitting}
                                   name="message"
                                   onChange={formProps.handleChange}
                                   size="default"
                                   type="textarea"
                                   rows={6}
                                   value={formProps.values.message}
                                   placeholder={t('CONTACT__FORM_MESSAGE_PLACEHOLDER')} />
                        </ScMessageLabel>

                        <FormikCheckbox
                          name="privacyPolicy"
                          label={t('CONTACT__FORM_PRIVACY_POLICY')}
                          link="/pages/_afAeKiYipDqMDZOnDclX/privacy-policy"
                          linkText={t('CONTACT__FORM_PRIVACY_POLICY_LINK')}
                        />

                        <ScActions>
                          <ScGoogleReCaptcha onVerify={setRecaptchaResponse} />

                          <ScSubmitBtn disabled={isEmpty(recaptchaResponse)}
                                       loading={formProps.isSubmitting}
                                       onClick={formProps.submitForm}>
                            {t('CONTACT__FORM_SUBMIT')}
                          </ScSubmitBtn>
                        </ScActions>
                      </ScFormContainer>
                    );
                  }}
                </Formik>
              </ScFormWrapper>
            </ScContentInRow>
          </ScContent>
        </ScWidthLimiter>

        <MapsIframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2513.3533811701864!2d4.640324151374804!3d50.95417035872145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c15f6668146edf%3A0x12874d5502e3233b!2sBelgian%20Bus%20Sales!5e0!3m2!1sen!2spt!4v1587741592315!5m2!1sen!2spt"
          allowFullScreen
          aria-hidden="false"
        />
      </ScFullSizedContainer>
    </GoogleReCaptchaProvider>
  );
};

export { Contact };
