import { E164_PHONE_NUMBER_REGEX } from '@hypercharge/bbs-website-commons/lib/constants';
import {
  BasicSellInfo,
  SellForm,
  VehicleSellInfo
} from '@hypercharge/bbs-website-commons/lib/types/bbs';
import { Button, Input, notification, Steps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';
import * as Yup from 'yup';
import bbs_logo from '../assets/images/bbs_logo.gif';
import CountrySelect from '../components/CountrySelect';
import { LayoutFixedWidthWrapper } from '../components/LayoutFixedWidthWrapper';
import LocalizedLink from '../components/LocalizedLink';
import { PhoneNumberInput } from '../components/PhoneNumberInput';
import { sendSellForm } from '../context/SellForm';
import { BBS_PRIMARY_COLOR, BBS_TEXT_COLOR, BREAKPOINTS } from '../utils/constants';
import { RequestState } from '../utils/types';
import { MetaTags } from '../components/MetaTags';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const { Step } = Steps;

type StepType<T> = {
  onNextStep: (info: T, actions: any, recaptchaResponse?: any) => Promise<void>;
  isValid?: (isValid: boolean) => void;
};

const basicInfo: BasicSellInfo = {
  address: '',
  email: '',
  firstName: '',
  lastName: '',
  telephone: '',
  countryCode: ''
};

const vehicleInfo: VehicleSellInfo = {
  make: '',
  model: '',
  chassisNumber: '',
  euronorm: '',
  gearbox: '',
  kw: 0,
  mileage: 0,
  year: 0
};

const basicInfoValidation = Yup.object().shape<BasicSellInfo>({
  address: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  telephone: Yup.string()
    .required()
    .matches(E164_PHONE_NUMBER_REGEX),
  countryCode: Yup.string().required()
});

const vehicleInfoValidation = Yup.object().shape<VehicleSellInfo>({
  chassisNumber: Yup.string()
    .required()
    .length(17),
  euronorm: Yup.string().required(),
  gearbox: Yup.string().required(),
  kw: Yup.number().required(),
  make: Yup.string().required(),
  mileage: Yup.number()
    .required()
    .min(0),
  model: Yup.string().required(),
  year: Yup.number()
    .required()
    .min(0)
});

const ScWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 2px 2px 4px 2px #e8e8e8;
    padding: 10px 20px;
    flex-grow: 1;
`;

const ScForm = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1em;
`;

const ScFormItem = styled.div`
    padding: 5px;
    width: 50%;

    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        width: 100%;
    }

    .error {
        border-color: red;
    }
`;

const ScFormItemFullRow = styled(ScFormItem)`
    width: 100%;
`;

const ScActions = styled.div`
    width: 100%;
    padding: 20px 0 20px 0;
`;

const ScButton = styled(Button)`
    margin-top: 15px;
    min-width: 100px;
`;

const ScCallToActionWrapper = styled.div`
    background-color: ${BBS_PRIMARY_COLOR};
    position: fixed;
    right: 0;
    width: 130px;
    height: 148px;
    border-radius: 4px 0 0 4px;
    display: flex;
    flex-direction: column;
`;

const ScCTAContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 20px 5px 0 5px;
    min-height: 130px;

    > img {
        object-fit: cover;
        align-self: center;
        width: 80px;
        height: 40px;
    }
`;

const ScTitle = styled.h1`
    font-size: 30px;
    color: ${BBS_TEXT_COLOR};
`;

const ScCTAText = styled.p`
    margin: 15px 0 5px 0;
    color: white;
`;

const ScCTAClickHere = styled.div`
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: 0 0 0 4px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    background-color: #77b3dc;
    color: white;
    text-align: center;
`;

const ScLayoutFixedWidthWrapper = styled(LayoutFixedWidthWrapper)`
    margin: 10px auto;
    @media screen and (max-width: ${BREAKPOINTS.sm}px) {
        padding: 0 10px 0 10px;
    }
`;

const ScTextArea = styled(TextArea)`
    resize: none;
`;

const StepBasicInfo = ({
                         basicInfo,
                         onNextStep,
                         isValid
                       }: { basicInfo: BasicSellInfo } & StepType<BasicSellInfo>) => {
  const { t } = useTranslation();

  function onSubmit(values, actions) {
    onNextStep(values, actions).then(() => null);
  }

  const formik = useFormik<BasicSellInfo>({
    initialValues: basicInfo,
    validationSchema: basicInfoValidation,
    onSubmit
  });

  useEffect(() => {
    isValid && isValid(formik.isValid);
  }, [isValid, formik]);

  return (
    <>
      <ScForm onSubmit={formik.handleSubmit}>
        <ScFormItem>
          <label>{t('CONTACT__FORM_FIRSTNAME')}</label>
          <Input
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            className={formik.errors.firstName && formik.touched.firstName ? 'error' : ''}
            placeholder="Olivia"
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('CONTACT__FORM_LASTNAME')}</label>
          <Input
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            className={formik.errors.lastName && formik.touched.lastName ? 'error' : ''}
            placeholder="Peeters"
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('CONTACT__FORM_PHONE')}</label>
          <PhoneNumberInput
            valid={!(formik.touched.telephone && formik.errors.telephone)}
            value={formik.values.telephone}
            onChange={value => {
              formik.setFieldValue('telephone', value.replace(/[^0-9+]+/g, ''));
            }}
            onBlur={() => {
              formik.setFieldTouched('telephone');
            }}
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('CONTACT__FORM_EMAIL')}</label>
          <Input
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className={formik.errors.email && formik.touched.email ? 'error' : ''}
            placeholder="emma.peeters@example.com"
          />
        </ScFormItem>
        <ScFormItemFullRow>
          <label>{t('SELL_BUS__FORM_ADDRESS')}</label>
          <ScTextArea
            name="address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            className={formik.errors.address && formik.touched.address ? 'error' : ''}
            placeholder="Kerkstraat 1"
          />
        </ScFormItemFullRow>
        <ScFormItemFullRow>
          <label>{t('SELL_BUS__FORM_COUNTRY')}</label>
          <CountrySelect
            value={formik.values.countryCode}
            placeholder={t('SELL_BUS__FORM_COUNTRY_PLACEHOLDER')}
            onChange={value => {
              formik.setFieldValue('countryCode', value);
            }}
            onBlur={() => {
              formik.setFieldTouched('telephone');
            }}
            valid={!(formik.touched.countryCode && formik.errors.countryCode)}
          />
        </ScFormItemFullRow>
        <ScActions>
          <ScButton type="primary" disabled={!formik.isValid} onClick={formik.submitForm}>
            {t('SELL_BUS__NEXT')}
          </ScButton>
        </ScActions>
      </ScForm>
    </>
  );
};

const StepVehicleInfo = ({
                           vehicleInfo,
                           isValid,
                           onNextStep
                         }: SellForm & StepType<VehicleSellInfo>) => {
  const { t, i18n: { language } } = useTranslation();
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);
  const recaptchaRef = useRef<any | null>(null);

  const formik = useFormik<VehicleSellInfo>({
    initialValues: vehicleInfo,
    validationSchema: vehicleInfoValidation,
    onSubmit
  });

  async function onSubmit(values, formActions) {
    await onNextStep(values, formActions, recaptchaResponse || '');

    if (recaptchaRef && recaptchaRef.current && recaptchaRef.current.reset) {
      recaptchaRef.current.reset();
    }
  }

  useEffect(() => {
    isValid && isValid(formik.isValid);
  }, [isValid, formik]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
      language={language}
      container={{
        element: 'google-captcha-element',
        parameters: {}
      }}
    >
      <ScForm onSubmit={formik.handleSubmit}>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_MAKE')}</label>
          <Input
            name="make"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.make}
            className={formik.errors.make && formik.touched.make ? 'error' : ''}
            placeholder="MAN, Volvo, Irisbus"
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_MODEL')}</label>
          <Input
            name="model"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.model}
            className={formik.errors.model && formik.touched.model ? 'error' : ''}
            placeholder="Lion, 7900, Proway"
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_YEAR')}</label>
          <Input
            type="number"
            name="year"
            min={0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={!!formik.values.year ? formik.values.year : new Date().getFullYear()}
            className={formik.errors.year && formik.touched.year ? 'error' : ''}
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_MILEAGE')} KM</label>
          <Input
            type="number"
            name="mileage"
            min={0}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={!!formik.values.mileage ? formik.values.mileage : 0}
            className={formik.errors.mileage && formik.touched.mileage ? 'error' : ''}
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_CHASSIS_NUMBER')}</label>
          <Input
            name="chassisNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.chassisNumber}
            className={formik.errors.chassisNumber && formik.touched.chassisNumber ? 'error' : ''}
            maxLength={17}
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_KW')}</label>
          <Input
            type="number"
            min={0}
            name="kw"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={!!formik.values.kw ? formik.values.kw : 0}
            className={formik.errors.kw && formik.touched.kw ? 'error' : ''}
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_GEARBOX')}</label>
          <Input
            name="gearbox"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.gearbox}
            className={formik.errors.gearbox && formik.touched.gearbox ? 'error' : ''}
            placeholder="Manual, Automatic"
          />
        </ScFormItem>
        <ScFormItem>
          <label>{t('SELL_BUS__FORM_EURONORM')}</label>
          <Input
            name="euronorm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.euronorm}
            className={formik.errors.euronorm && formik.touched.euronorm ? 'error' : ''}
            placeholder="Euro 1, Euro 2"
          />
        </ScFormItem>
        <ScActions>
          <GoogleReCaptcha onVerify={setRecaptchaResponse} />

          <ScButton
            type="primary"
            disabled={!formik.isValid || isEmpty(recaptchaResponse)}
            onClick={formik.submitForm}
          >
            {t('CONTACT__FORM_SUBMIT')}
          </ScButton>
        </ScActions>
      </ScForm>
    </GoogleReCaptchaProvider>
  );
};

const SellBus = ({ breadcrumbs }: { breadcrumbs: React.ReactNode }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [isCurrentStepValid, setIsCurrentStepValid] = useState<boolean>(false);

  const [completeForm, setCompleteForm] = useState<SellForm>({
    basicInfo: { ...basicInfo },
    vehicleInfo: { ...vehicleInfo }
  });

  function changeStepTo(step: number) {
    setCurrentStep(step);
  }

  async function send(form: SellForm, recaptchaResponse: any, formActions: any) {
    const { requestState } = await sendSellForm(form, recaptchaResponse);
    if (requestState === RequestState.ERROR) {
      const message = {
        message: t('ERROR_BOUNDARY__TITLE'),
        description: ''
      };

      notification.error({ ...message });
    }

    if (requestState === RequestState.SUCCESS) {
      formActions.resetForm();
      formActions.setSubmitting(false);
      notification.success({ message: t('SELL_BUS__SUBMIT_SUCCESS') });
    }
  }

  return (
    <ScLayoutFixedWidthWrapper>
      <MetaTags title={t('NAVIGATION__BUYERS_INFO')} />
      {breadcrumbs}
      <ScWrapper>
        <ScTitle>{t('NAVIGATION__BUYERS_INFO')}</ScTitle>
        <Steps onChange={changeStepTo} current={currentStep}>
          <Step
            title={t('SELL_BUS__BASIC_INFORMATION')}
            status={currentStep > 0 ? 'finish' : undefined}
          />
          <Step
            title={t('SELL_BUS__VEHICLE_DATA')}
            status={currentStep > 1 ? 'finish' : undefined}
          />
        </Steps>
        {currentStep === 0 && (
          <StepBasicInfo
            basicInfo={completeForm.basicInfo}
            onNextStep={async info => {
              if (!isCurrentStepValid) return;

              setCompleteForm({ ...completeForm, basicInfo: info });
              setCurrentStep(currentStep + 1);
            }}
            isValid={setIsCurrentStepValid}
          />
        )}
        {currentStep === 1 && (
          <StepVehicleInfo
            {...completeForm}
            onNextStep={async (info, formActions, recaptchaResponse) => {
              const form: SellForm = { ...completeForm, vehicleInfo: info };
              if (!isCurrentStepValid) return;

              setCompleteForm(form);
              send(form, recaptchaResponse, formActions).then(() => null);
            }}
            isValid={setIsCurrentStepValid}
          />
        )}
      </ScWrapper>
    </ScLayoutFixedWidthWrapper>
  );
};

const SellCTA = () => {
  const { t } = useTranslation();

  return (
    <LocalizedLink to={'/sell_bus'}>
      <ScCallToActionWrapper>
        <ScCTAContent>
          <img src={bbs_logo} alt="Belgian Bus Company Logo" />
          <ScCTAText>{t('SELL_CTA_TEXT')}</ScCTAText>
        </ScCTAContent>
        <ScCTAClickHere>{t('SELL_CTA_BUTTON')}</ScCTAClickHere>
      </ScCallToActionWrapper>
    </LocalizedLink>
  );
};

export { SellBus, SellCTA };
